<template>
  <div>
      <div class="greeting-card">
          <h3>Hello! 👋 </h3>
          <p>{{Message}}</p>
          <div class="login-greeting secondary-button" v-on:click="login">Login</div>
      </div>
  </div>
</template>

<script>
export default {
  name: "GreetingCard",
  data() {
    return {
      Message:
        "Welcome to the Proofed Test Tracker. Please be aware that this app is still in a beta phase. You can report bugs and feedback to Cameron Webby via Slack. Thank you. "
    };
  },
  methods: {
    login() {
      this.$auth.loginWithRedirect();
    },
    logout() {
      this.$auth.logout();
      this.$router.push({ path: "/" });
    }
  }
};
</script>

<style lang="sass" scoped>
.greeting-card
    background: white
    width: 400px
    padding: 40px
    top: 30vh
    position: absolute
    left: calc(50vw - 200px)
    margin: 0 auto
    box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.05)
    text-align: center

    
.login-greeting
    cursor: pointer
</style>
