import axios from "axios";

const testAPI = "http://localhost:5000/"; //test api
const liveAPI = "https://api.proofedapps.com/"; //prod api

const apiURL = process.env.NODE_ENV === "production" ? liveAPI : testAPI;

const token = "6dd2707c-3a44-4e2e-86f3-1b01c91dc288";

export default axios.create({
    baseURL: apiURL,
    headers: {
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        //need to sort this
        Authorization: `Bearer ${token}`,
    },
});