<template>
  <div>
<svg width="27" height="34" viewBox="0 0 27 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M26.3707 33.4991L0.999997 33.4991L1 1L26.3707 1L26.3707 33.4991Z" fill="white"/>
<path d="M2.06055 24.5332H25.2502V32.4357H2.06055V24.5332Z" fill="#5F8CFF" stroke="#92A0BF"/>
<path d="M6.83008 3.75977L23.594 3.75977" stroke="#92A0BF" stroke-miterlimit="10"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.85761 3.75986C5.85761 3.97025 5.68705 4.14081 5.47666 4.14081V4.14081C5.26626 4.14081 5.0957 3.97025 5.0957 3.75986V3.75986C5.0957 3.54946 5.26626 3.37891 5.47666 3.37891V3.37891C5.68705 3.37891 5.85761 3.54946 5.85761 3.75986V3.75986Z" fill="#92A0BF"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.12421 3.75986C4.12421 3.97025 3.95365 4.14081 3.74326 4.14081V4.14081C3.53286 4.14081 3.3623 3.97025 3.3623 3.75986V3.75986C3.3623 3.54946 3.53286 3.37891 3.74326 3.37891V3.37891C3.95365 3.37891 4.12421 3.54946 4.12421 3.75986V3.75986Z" fill="#92A0BF"/>
<path d="M6.5625 9.5249L20.9137 9.5249" stroke="#92A0BF" stroke-miterlimit="10"/>
<path d="M6.5625 16.084H20.9136" stroke="#92A0BF" stroke-miterlimit="10"/>
<path d="M6.5625 12.8042H20.9136" stroke="#92A0BF" stroke-miterlimit="10"/>
<path d="M6.5625 19.3691H15.5923" stroke="#92A0BF" stroke-miterlimit="10"/>
<path d="M25.877 6.51081V33H1.49609V1H20.3226H25.877V6.51081Z" stroke="#92A0BF" stroke-miterlimit="10"/>
<path d="M10.4475 30.5724C11.7216 30.5724 12.5415 29.6817 12.5415 28.3546C12.5415 27.0274 11.7216 26.1426 10.4475 26.1426C9.17342 26.1426 8.35352 27.0274 8.35352 28.3546C8.35352 29.6817 9.17342 30.5724 10.4475 30.5724ZM10.4475 29.9C9.78098 29.9 9.22651 29.3573 9.22651 28.3546C9.22651 27.3518 9.78098 26.815 10.4475 26.815C11.1141 26.815 11.6685 27.3518 11.6685 28.3546C11.6685 29.3573 11.1141 29.9 10.4475 29.9Z" fill="white"/>
<path d="M13.1386 26.2193V30.4958H13.9821V28.6495H16.1292V27.9653H13.9821V26.9035H16.2531V26.2193H13.1386Z" fill="white"/>
<path d="M16.6352 26.2193V30.4958H17.4787V28.6495H19.6258V27.9653H17.4787V26.9035H19.7496V26.2193H16.6352Z" fill="white"/>
</svg>


  </div>
</template>

<script>
export default {
    name: 'FileIcon'
}
</script>
