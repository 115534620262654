var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"test-marker-grid"},[_c('div',{staticClass:"test-marker-channel-1"},[_c('div',{staticClass:"test-marker-score-overview"},[_c('div',{staticClass:"score-section"},[_c('h5',[_vm._v("Overall Score")]),_c('span',[_vm._v(_vm._s(_vm.Correct)+"/"+_vm._s(_vm.CalculatedTotalMarks)+" "),_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v("("+_vm._s(_vm.CorrectPercent)+"%)")])])]),_c('div',{staticClass:"score-section"},[_c('h5',[_vm._v("Boundaries")]),_c('ul',{staticClass:"boundary-list"},[_c('li',{class:_vm.CorrectPercent >= _vm.TestData.TestType.high_pass_rate * 100
                  ? 'boundary-text-active'
                  : 'boundary-text'},[_vm._v(" Distinction "+_vm._s(_vm.TestData.TestType.high_pass_rate * 100)+"% ")]),_c('li',{class:_vm.CorrectPercent >= _vm.TestData.TestType.pass_rate * 100 &&
                _vm.CorrectPercent < _vm.TestData.TestType.high_pass_rate * 100
                  ? 'boundary-text-active'
                  : 'boundary-text'},[_vm._v(" Pass "+_vm._s(_vm.TestData.TestType.pass_rate * 100)+"% ")])])]),_c('div',{staticClass:"score-section"},[_c('h5',[_vm._v("Overall Decision")]),_c('span',[_vm._v(_vm._s(_vm.Decision))])]),_c('div',{class:!_vm.TestData.final_results_submitted
              ? 'secondary-button pointer'
              : 'inactive-button notallowed-pointer',attrs:{"data-target":"#exampleModalLong","data-toggle":"modal"}},[_vm._v(" Submit Marks ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.TestData.final_results_submitted),expression:"!TestData.final_results_submitted"}],staticClass:"pointer tertiary-button",staticStyle:{"margin-top":"12px"},on:{"click":function($event){return _vm.saveMarks(true)}}},[_vm._v(" Save Marks ")])])]),_c('div',{staticClass:"test-marker-channel-2"},[_c('div',{staticClass:"test-marker-test-overview"},[_c('div',{staticClass:"overview-header"},[_c('h5',[_vm._v(" "+_vm._s(!_vm.TestData.final_results_submitted ? "Pending Results" : "Already Marked")+" ")]),_c('h4',[_vm._v(_vm._s(_vm.TestData.TestType.test_type_name))]),_c('p',[_vm._v(_vm._s(_vm.TestData.TestType.test_type_description))]),(false)?_c('DataRow',{attrs:{"fieldName":'Editor Comments',"fieldData":_vm.TestData.candidate_comments}}):_vm._e()],1),_c('hr',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]}),_c('div',{staticClass:"overview-field-grid"},[_c('div',[_c('DataRow',{attrs:{"fieldName":'Email',"fieldData":_vm.TestData.candidate_email ? _vm.TestData.candidate_email : '-'}}),_c('DataRow',{attrs:{"fieldName":'ProofedID',"fieldData":_vm.TestData.proofed_id,"linkURL":("https://team.getproofed.com/editors/edit/" + (_vm.TestData.proofed_id))}}),_c('DataRow',{attrs:{"fieldName":'Attempt Num',"fieldData":_vm.TestData.attempt_num}})],1),_c('div',[_c('DataRow',{attrs:{"fieldName":'Track Change',"fieldData":_vm.TestData.track_change_url
                  ? 'Click to Download'
                  : 'Not Uploaded',"linkURL":_vm.TestData.track_change_url}}),_c('DataRow',{attrs:{"fieldName":'Clean Copy',"fieldData":_vm.TestData.clean_copy_url ? 'Click to Download' : 'Not Uploaded',"linkURL":_vm.TestData.clean_copy_url}}),(true)?_c('DataRow',{attrs:{"fieldName":'Editor Comments',"fieldData":_vm.TestData.candidate_comments}}):_vm._e()],1)])]),_c('div',{staticClass:"test-marker-test-overview",staticStyle:{"margin-top":"12px"}},[_c('span',{staticStyle:{"font-size":"12px","margin":"0 0 20px 12px"}},[_vm._v("Comments")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.TestData.general_comments),expression:"TestData.general_comments"}],staticClass:"tt-text-area",attrs:{"disabled":_vm.TestData.final_results_submitted,"type":"textarea","placeholder":"Comments to Candidate"},domProps:{"value":(_vm.TestData.general_comments)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.TestData, "general_comments", $event.target.value)}}})]),_c('div',{staticClass:"marking-tool"},[_c('table',{staticClass:"marking-tool-table"},[_c('thead',[_c('td',[_vm._v("Q. Num")]),(_vm.ShowCommentsColumn)?_c('td',[_vm._v("Editor Comments")]):_vm._e(),_c('td',[_vm._v("Reviewer Remarks")]),_c('td',{attrs:{"width":"90"}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.TestData.final_results_submitted),expression:"!TestData.final_results_submitted"}],staticStyle:{"margin":"0 auto","cursor":"pointer"},attrs:{"src":"https://aux4.iconspalace.com/uploads/950447276790987536.png","width":"30"},on:{"click":function($event){return _vm.saveMarks(true)}}})])]),_vm._l((_vm.TestData.Result),function(Result){return _c('tr',{key:Result.ID,style:(Result.passed === true
                ? 'background-color: #AADBC9'
                : Result.passed === false
                ? 'background-color: #FFE1E1'
                : '')},[_c('td',[_vm._v(_vm._s(Result.question_id)+" "+_vm._s(Result.marks)+" mark")]),(_vm.ShowCommentsColumn)?_c('td',[_vm._v(_vm._s(Result.editor_comments))]):_vm._e(),_c('td',[_vm._v(_vm._s(Result.marker_remarks))]),_c('td',[_c('label',{directives:[{name:"show",rawName:"v-show",value:(!_vm.TestData.final_results_submitted),expression:"!TestData.final_results_submitted"}],staticClass:"default-radio"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(Result.passed),expression:"Result.passed"}],attrs:{"type":"radio"},domProps:{"value":false,"checked":_vm._q(Result.passed,false)},on:{"change":function($event){return _vm.$set(Result, "passed", false)}}})]),_c('label',{directives:[{name:"show",rawName:"v-show",value:(!_vm.TestData.final_results_submitted),expression:"!TestData.final_results_submitted"}],class:{ redborder: false === true }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(Result.passed),expression:"Result.passed"}],attrs:{"type":"radio"},domProps:{"value":true,"checked":_vm._q(Result.passed,true)},on:{"change":function($event){return _vm.$set(Result, "passed", true)}}})])])])})],2)])])]),_c('SaveModal',{attrs:{"alreadyMarked":_vm.TestData.final_results_submitted},on:{"onSubmit":function($event){return _vm.submitMarks()}}}),_c('div',{staticClass:"snackbar",attrs:{"id":"save-snackbar"}},[_vm._v("Test Successfully Saved")]),_c('div',{staticClass:"snackbar",attrs:{"id":"submitted-snackbar"}},[_vm._v(" Test Successfully Submitted ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }